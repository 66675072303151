import { html, LitElement } from 'lit';

class StandardList extends LitElement {

    static properties = {
        jsonFileUrl: { type: String },
        groups: { type: Object },
      };

    constructor() {
        super();

        this.groups = {};
    }

    createRenderRoot() {
        return this;
      }
    
    firstUpdated() {
      this._prepareJSONData();
    }

  _prepareJSONData() {
    return new Promise((resolve, reject) => {
      if (!this.jsonFileUrl) {
          return reject("No data JSON file found!");
      }
      fetch(this.jsonFileUrl).then(res => res.json()).then(list => {
          const groups = {};
          const sortedList = list.sort((first, second) => first.ID_STANDARD > second.ID_STANDARD ? 1 : -1)
          sortedList.forEach(entry => {
              if (!groups[entry.ID_STANDARD_RADA]) {
                groups[entry.ID_STANDARD_RADA] = {
                    ID_STANDARD_RADA: entry.ID_STANDARD_RADA,
                    RADA: entry.RADA,
                    NAZEV: entry.NAZEV,
                    POZNAMKA: entry.POZNAMKA,
                    entries: [],
                }
                groups[entry.ID_STANDARD_RADA].entries.push(entry);
              } else {
                groups[entry.ID_STANDARD_RADA].entries.push(entry);
              }
          });
          this.groups = groups;
          resolve();
      })
  });
}

    render() {
        const groupEnum = Object.keys(this.groups);

        return html`
            <div class="container">
                <div class="row">
                    ${groupEnum.map(groupId => {
                        const group = this.groups[groupId];
                        return html`
                            <div class="col-12 mb-4">
                                <h6 class="mb-0" style="line-height: 1.2;">${`Řada ${group.RADA} (${group.NAZEV})`}</h6>
                                <p class="mb-1">${group.POZNAMKA}</p>
                                ${group.entries.map(entry => html`
                                    <div class="my-1">
                                        <span>${entry.CISLO}</span>
                                        ${!entry.NAZEV_CZ ? "" : entry.URL_CZ ? 
                                            html`<a href="${entry.URL_CZ}">${entry.NAZEV_CZ}</a>` : 
                                            html`<span>${entry.NAZEV_CZ}</span>`}
                                        ${!entry.NAZEV_EN ? "" : entry.URL_EN ? 
                                            html`<a href="${entry.URL_EN}">${entry.NAZEV_EN}</a>` : 
                                            html`<span>${entry.NAZEV_EN}</span>`}
                                    </div>
                                `)}
                            </div>
                        `
                    })}
                </div>
            </div>
        `
    }

}

window.customElements.define('standard-list', StandardList);